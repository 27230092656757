import React, { useState } from 'react';
import { connect } from 'react-redux';
import R from 'ramda';
import PropTypes from 'prop-types';
import { HeroFullCombined, HeroFullOverlay } from '@cotyorg/ccx-organism-hero';
import Heading from '@cotyorg/ccx-atoms/Heading';
import { CardsCarousel } from '@cotyorg/ccx-organism-carousel';
import { CardoGroup } from '@cotyorg/ccx-organism-cardo-group';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { VideoPlayer } from '@cotyorg/ccx-organism-video';
import {
  trackVideoPlayEvent,
  trackVideoMilestoneEvent,
} from '@cotyorg/ccx-utils/js/tracking/adobe-dtm/events/video';
import Picture from '@cotyorg/ccx-atoms/Picture';
import Link from '@cotyorg/ccx-atoms/Link';
import StandardLayout from '../../templates/StandardLayout';
import SectionHeader from '../../atoms/SectionHeader';
import HeroFullOverlayContentFirst from '../../organisms/HeroFullOverlayContentFirst';
import { homeCardoBreakpointConfig } from './cardo.config';
import ShadeFinder from '../../organisms/ShadeFinder/components/ShadeFinder';

const HomeComponent = ({
  sectionHeadings,
  ctas,
  cardoGroup,
  hero,
  videos,
  ctaVto,
  presentationLocale,
  shadeFinder,
  images,
  bannerCta,
}) => {
  const [vtoOpen, setVtoOpen] = useState(false);
  const [vtoProductSlug, setVtoProductSlug] = useState();

  return (
    <StandardLayout>
      <div className="homepage">
        {!!R.prop('mainVideo', videos) && (
          <div className="homepage__video-main">
            <VideoPlayer
              muted
              lightPlayIcon={<></>}
              autoPlay
              videosStoreDataKey="mainVideo"
              showControls={false}
              config={{
                youtube: {
                  playerVars: {
                    controls: 1,
                  },
                  embedOptions: {
                    host: 'https://www.youtube-nocookie.com',
                  },
                },
                file: {
                  attributes: {
                    controls: true,
                  },
                },
              }}
              onProgress={({ url, duration, progress, videoMilestone }) =>
                trackVideoMilestoneEvent({
                  url,
                  duration,
                  progress,
                  videoMilestone,
                  title: R.path(['mainVideo', 'title'], videos),
                })
              }
              onPlay={({ url, duration, progress }) =>
                trackVideoPlayEvent({
                  url,
                  duration,
                  progress,
                  title: R.path(['mainVideo', 'title'], videos),
                })
              }
            />
          </div>
        )}

        {R.prop('mainHero', hero) && (
          <HeroFullCombined
            heroStoreDataKey="mainHero"
            layout="vertical"
            verticalMediumContentColumns={12}
            verticalLargeContentColumns={12}
            verticalExtraLargeContentColumns={9}
          />
        )}

        <OptionalSection
          renderCondition={R.prop('banner', images)}
          section={() => (
            <div className="homepage__banner">
              {R.prop('url', bannerCta) ? (
                <Link
                  link={R.prop('url', bannerCta)}
                  target={R.prop('openInNewWindow', bannerCta) ? '_blank' : ''}
                >
                  <Picture
                    alt={R.path(['banner', 0, 'imageAlt'], images)}
                    src={R.path(['banner', 0, 'imageSrc'], images)}
                    srcset={R.path(['banner', 0, 'imageSrcSet'], images)}
                  />
                </Link>
              ) : (
                <Picture
                  alt={R.path(['banner', 0, 'imageAlt'], images)}
                  src={R.path(['banner', 0, 'imageSrc'], images)}
                  srcset={R.path(['banner', 0, 'imageSrcSet'], images)}
                />
              )}
            </div>
          )}
        />

        <div className="grid-container">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <SectionHeader>
                <Heading extraClass="section__title" level={2}>
                  {R.path(['heroSection1Heading', 'heading'])(sectionHeadings)}
                </Heading>

                <Heading extraClass="section__sub-title" level={3}>
                  {R.path(['heroSection1Heading', 'introduction'])(
                    sectionHeadings
                  )}
                </Heading>
              </SectionHeader>

              <div className="hero-absolute-image">
                <HeroFullOverlay heroStoreDataKey="heroSection1Hero1" />

                <HeroFullOverlay heroStoreDataKey="heroSection1Hero2" />

                {hero.heroSection1Hero3 && (
                  <HeroFullOverlay heroStoreDataKey="heroSection1Hero3" />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="homepage__carousel-1">
          <div className="grid-container">
            <div className="grid-x grid-margin-x pre-offset">
              <div className="cell">
                <CardsCarousel
                  cards={{
                    cardsStoreDataKey: 'carousel1',
                    isTitleUnderlined: false,
                    hasBackground: false,
                    isSmallTitle: true,
                    fullWidth: true,
                    headingLevel: 3,
                    fixedHeight: true,
                    hideDescription: true,
                  }}
                  carousel={{
                    carouselsStoreDataKey: 'carousel1',
                    headingLevel: 4,
                    rightChevronIconSrc: '/ccx-files/assets/arrow-right.svg',
                    leftChevronIconSrc: '/ccx-files/assets/arrow-left.svg',
                  }}
                  cardsPerPageMap={{
                    xsmall: 1,
                    small: 2,
                    medium: 3,
                    large: 4,
                    xlarge: 4,
                  }}
                />
              </div>
            </div>
          </div>

          <div className="homepage__pattern-background offset">
            {presentationLocale === 'en-gb' && ctaVto.carouselSection1.url ? (
              <div className="homepage__pattern-background homepage__buttons-wrapper">
                <div className="button-wrapper">
                  <a
                    className="homepage__cta"
                    href={R.path(['carouselSection1', 'url'], ctaVto)}
                  >
                    {R.path(['carouselSection1', 'text'], ctaVto)}
                  </a>
                </div>

                <div className="button-wrapper">
                  <a
                    className="homepage__cta"
                    href={R.path(['carouselSection1', 'url'], ctas)}
                  >
                    {R.path(['carouselSection1', 'text'], ctas)}
                  </a>
                </div>
              </div>
            ) : (
              <div className="flex-container align-center">
                <a
                  className="homepage__cta"
                  href={R.path(['carouselSection1', 'url'], ctas)}
                >
                  {R.path(['carouselSection1', 'text'], ctas)}
                </a>
              </div>
            )}
          </div>
        </div>

        <div className="grid-container pre-offset">
          <div className="grid-x grid-margin-x">
            <div className="cell">
              <SectionHeader>
                <Heading extraClass="section__title" level={2}>
                  {R.path(['carouselSection2Heading', 'heading'])(
                    sectionHeadings
                  )}
                </Heading>
              </SectionHeader>
              <CardsCarousel
                cards={{
                  cardsStoreDataKey: 'carousel2',
                  isTitleUnderlined: false,
                  hasBackground: false,
                  isSmallTitle: true,
                  fullWidth: true,
                  headingLevel: 3,
                  fixedHeight: true,
                  hideDescription: true,
                  additionalOnClick: shadeFinder
                    ? ({ slug }) => {
                        setVtoOpen(true);
                        setVtoProductSlug(slug);
                      }
                    : null,
                }}
                carousel={{
                  carouselsStoreDataKey: 'carousel2',
                  headingLevel: 4,
                  rightChevronIconSrc: '/ccx-files/assets/arrow-right.svg',
                  leftChevronIconSrc: '/ccx-files/assets/arrow-left.svg',
                }}
                cardsPerPageMap={{
                  xsmall: 1,
                  small: 2,
                  medium: 3,
                  large: 4,
                  xlarge: 4,
                }}
              />
            </div>
          </div>
        </div>

        <OptionalSection
          renderCondition={R.prop('cardSection1Cards', cardoGroup)}
          section={() => (
            <div className="offset">
              <div className="grid-container">
                <div className="grid-x grid-margin-x">
                  <div className="cell">
                    <SectionHeader>
                      <Heading extraClass="section__title" level={2}>
                        {R.path(['cardSection1Heading', 'heading'])(
                          sectionHeadings
                        )}
                      </Heading>

                      <Heading extraClass="section__sub-title" level={3}>
                        {R.path(['cardSection1Heading', 'introduction'])(
                          sectionHeadings
                        )}
                      </Heading>
                    </SectionHeader>
                  </div>
                </div>

                <div className="grid-x grid-padding-x flex-container align-middle align-center">
                  <CardoGroup
                    data="cardSection1Cards"
                    contentTheme="alpha"
                    baseBreakpointConfig={homeCardoBreakpointConfig}
                  />
                </div>
              </div>
            </div>
          )}
        />

        <div className="homepage__hero-section-2">
          <div className="grid-container">
            <div className="grid-x grid-margin-x">
              <div className="cell">
                <div className="hero-absolute-image">
                  <HeroFullOverlayContentFirst heroStoreDataKey="heroSection2Hero" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!!shadeFinder && (
          <div className="homepage__section-try-on">
            <ShadeFinder
              shadeFinderConfiguration={shadeFinder}
              isOpen={vtoOpen}
              onClose={() => setVtoOpen(false)}
              vtoActualProductSlug={vtoProductSlug}
            />
          </div>
        )}
      </div>
    </StandardLayout>
  );
};

HomeComponent.propTypes = {
  sectionHeadings: PropTypes.objectOf(PropTypes.object),
  ctas: PropTypes.objectOf(PropTypes.object),
  ctaVto: PropTypes.objectOf(PropTypes.object),
  cardoGroup: PropTypes.objectOf(PropTypes.object),
  hero: PropTypes.objectOf(PropTypes.object) || PropTypes.null,
  videos: PropTypes.object,
  presentationLocale: PropTypes.string,
  shadeFinder: PropTypes.object,
  images: PropTypes.objectOf(PropTypes.array),
  bannerCta: PropTypes.shape({
    link: PropTypes.shape({
      text: PropTypes.string,
      url: PropTypes.string,
      openInNewWindow: PropTypes.bool,
    }),
  }),
};

export default connect(({ content, presentationLocale }) => ({
  sectionHeadings: R.prop('sectionHeadings', content),
  ctas: R.prop('ctas', content),
  ctaVto: R.prop('ctaVto', content),
  cardoGroup: R.prop('cardoGroup', content),
  hero: R.prop('hero', content),
  videos: R.prop('videos', content),
  shadeFinder: R.prop('shadeFinder')(content),
  images: R.prop('images', content),
  presentationLocale,
  bannerCta: R.prop('bannerCta', content),
}))(HomeComponent);
