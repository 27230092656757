import React from 'react';
import PropTypes from 'prop-types';
import R from 'ramda';
import { connect } from 'react-redux';
import { Cookie } from '@cotyorg/ccx-organism-cookie';
import { HeaderTwoRow } from '@cotyorg/ccx-organism-header';
import { FooterTwoRow } from '@cotyorg/ccx-organism-footer';
import { Social } from '@cotyorg/ccx-molecules/Social';
import { Newsletter } from '@cotyorg/ccx-molecules/Newsletter';
import Search from '@cotyorg/ccx-organism-search/components/Search';
import OptionalSection from '@cotyorg/ccx-utils/js/components/OptionalSection';
import { BasketModalComponent } from '../../molecules/basket';
import {
  showModal as showModalAction,
  hideModal as hideModalAction,
  getBasketQuantity,
} from '../../molecules/basket/modules';

const StandardLayout = ({
  cookieContent,
  children,
  basketLink,
  isServedFromLegacySite,
  showModal,
  hideModal,
  basketQuantity,
  socialHeading,
  newsletter,
  search,
  presentationLocale,
  isOneTrustEnabled,
  showNewsletter,
}) => (
  <div className={presentationLocale}>
    <HeaderTwoRow
      logoSrc="/ccx-files/assets/logo.png"
      logoAlt="Rimmel London"
      leftChevronIconSrc="/ccx-files/assets/arrow-left.svg"
      rightChevronIconSrc="/ccx-files/assets/arrow-right.svg"
      closeIconSrc="/ccx-files/assets/cross.svg"
      hamburgerIconSrc="/ccx-files/assets/hamburger.svg"
      logoPosition="center"
      themeName="filled"
      navType="featuredProducts"
      showSearch
      showBasket
      showMiniCart={presentationLocale === 'en-gb'}
      searchIconSrc="/ccx-files/assets/search.svg"
      miniCartIconSrc="/ccx-files/assets/basket.svg"
      basketLink={basketLink}
      onMouseEnter={showModal}
      onMouseLeave={hideModal}
      cartQuantity={basketQuantity}
      miniCart={() => <BasketModalComponent />}
      logoWidth={300}
      logoHeight={49}
    />

    <Search
      appElement="#app"
      searchIconSrc="/ccx-files/assets/search-simple.svg"
      closeIconSrc="/ccx-files/assets/cancel-simple.svg"
      clearSearchIconSrc="/ccx-files/assets/erase-simple.svg"
      searchURL={R.prop('url')(search)}
      locale={R.prop('locale')(search)}
      presentationLocale={presentationLocale}
      searchPath="/search/"
      searchFieldName="search"
      searchButtonHidden
      isServedFromLegacySite={isServedFromLegacySite}
    />

    {children}

    <div className="social__pattern-background">
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell medium-8">
            <Social
              socialIconSrc={{
                facebook: '/ccx-files/assets/facebook-white.svg',
                instagram: '/ccx-files/assets/instagram-white.svg',
                tiktok: '/ccx-files/assets/tiktok-white.svg',
                pinterest: '/ccx-files/assets/pinterest-white.svg',
                twitter: '/ccx-files/assets/twitter-white.svg',
                youtube: '/ccx-files/assets/youtube-white.svg',
                tumblr: '/ccx-files/assets/tumblr-white.svg',
                vk: '/ccx-files/assets/vk.svg',
              }}
              heading={socialHeading}
              linkTarget="_blank"
            />
          </div>

          <OptionalSection
            renderCondition={showNewsletter}
            section={() => (
              <div className="cell medium-8">
                <div className="newsletter-custom">
                  <Newsletter
                    buttonType="icon"
                    buttonIconSrc="/ccx-files/assets/chevron-white-right.svg"
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>

    <FooterTwoRow
      isServedFromLegacySite={isServedFromLegacySite}
      minusIconSrc="/ccx-files/assets/arrow-left.svg"
      plusIconSrc="/ccx-files/assets/arrow-left.svg"
      countrySelector={{
        visible: true,
        beforeCopyright: true,
        showToggleIcon: false,
      }}
      navigationLinkIconHeight={15}
      navigationLinkIconWidth={15}
    />

    <OptionalSection
      renderCondition={!isOneTrustEnabled}
      section={() => (
        <Cookie
          position="bottom"
          content={cookieContent}
          closeIconSrc="/ccx-files/assets/cross-white.svg"
        />
      )}
    />
  </div>
);

StandardLayout.propTypes = {
  cookieContent: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    dismissText: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  basketLink: PropTypes.string,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  basketQuantity: PropTypes.number,
  isServedFromLegacySite: PropTypes.bool,
  socialHeading: PropTypes.string,
  search: PropTypes.shape({
    url: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    contentLabel: PropTypes.string,
    placeholderText: PropTypes.string,
    clearSearchTermHistoryButtonText: PropTypes.string,
    popularSearches: PropTypes.array,
  }),
  newsletter: PropTypes.objectOf(PropTypes.any),
  presentationLocale: PropTypes.string,
  isOneTrustEnabled: PropTypes.bool,
  showNewsletter: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  basketQuantity: getBasketQuantity(state),
  basketLink: R.path(['content', 'navigation', 'basketLink'], state),
  cookieContent: R.path(['content', 'cookie'], state),
  isServedFromLegacySite: R.path(['status', 'isServedFromLegacySite'], state),
  search: R.path(['content', 'search'], state),
  newsletter: R.path(['content', 'newsletter'], state),
  presentationLocale: R.prop('presentationLocale', state),
  isOneTrustEnabled: R.path(['content', 'isOneTrustEnabled'], state),
  showNewsletter: R.path(['content', 'newsletter', 'submitButtonText'], state),
});

const mapDispatchToProps = (dispatch) => ({
  showModal: () => dispatch(showModalAction()),
  hideModal: () => dispatch(hideModalAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StandardLayout);
